import React from 'react'
import ContextConsumer from "../context/LanguageContext"
import { graphql, useStaticQuery } from "gatsby"
import Header from "../layouts/header"
import RichText from "../components/rich-text"
import SEO from "../components/seo"
import Section from "../layouts/section"
import Bunting from "../images/bunting.svg"
import SVGWrapper from "../layouts/svg-wrapper"

const IndexPage = () => {
  const craftData = useStaticQuery(graphql`    
    query IndexQuery {
      craftapi {
        entryEN: entry (slug: "home", siteId: "1") {
          ... on CraftAPI_home_home_Entry {
            Heading
            body
          }
        }

        entryCY: entry (slug: "home", siteId: "2") {
          ... on CraftAPI_home_home_Entry {
            Heading
            body
          }
        }
      }
    }
  `)

  const {
    craftapi: {
      entryEN : en,
      entryCY: cy,
    }
  } = craftData


  return (
    <ContextConsumer>
      {({ data }) => (
        <>
          <SEO title="Home"/>
          <main>
            <SVGWrapper>
              <Bunting />
            </SVGWrapper>
            <Section
              margin="2rem auto 0 auto"
              padding="2rem"
              textAlign="center"
            >
              <Header centre level={1} margin={`0 0 2rem 0`}>{data.currentLanguage === 'en' ? en.Heading : cy.Heading}</Header>
              <RichText dangerouslySetInnerHTML={ data.currentLanguage === 'en' ? { __html: en.body } : { __html: cy.body }} />
            </Section>
          </main>
        </>
      )}
    </ContextConsumer>
  )
}

export default IndexPage
